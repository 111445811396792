import { ConnectProps, ConnectState, Route, UserModelState } from '@/models/connect';

import Authorized from '@/utils/Authorized';
import React from 'react';
import Redirect from 'umi/redirect';
import { connect } from 'dva';
import pathToRegexp from 'path-to-regexp';

interface AuthComponentProps extends ConnectProps {
  user: UserModelState;
}

const getRouteAuthority = (path: string, routeData: Route[]) => {
  let authorities: string[] | string | undefined;
  routeData.forEach(route => {
    // match prefix
    if (pathToRegexp(`${route.path}(.*)`).test(path)) {
      // exact match
      if (route.path === path) {
        authorities = route.authority || authorities;
      }
      // get children authority recursively
      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};

const AuthComponent: React.FC<AuthComponentProps> = ({
  children,
  route = {
    routes: [],
  },
  location = {
    pathname: '',
  },
  user,
}) => {
  const { currentUser } = user;
  let { routes = [] } = route;
  const AList = localStorage.getItem('AuthList')
  let authList: any = []
  try {
    authList = JSON.parse((AList || '[]'))
  } catch (e) {
    console.log('e', e)
  }
  routes = routes.filter(r => !r.name || authList.indexOf(r.name) > -1)
  const isLogin = localStorage.getItem('Authorization')
  const redirect = isLogin ? routes[0].routes[0].path : '/user/login'
  return (
    <Authorized
      authority={getRouteAuthority(location.pathname, routes) || ''}
      noMatch={<Redirect to={redirect} />}
    >
      {children}
    </Authorized>
  );
};

export default connect(({ user }: ConnectState) => ({
  user,
}))(AuthComponent);
