import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/usr/src/app/src/pages/.umi/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/worder/print',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__worder__print" */ '../worder/print'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../worder/print').default,
        exact: true,
      },
      {
        path: '/user',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/UserLayout').default,
        routes: [
          {
            path: '/user',
            redirect: '/user/login',
            exact: true,
          },
          {
            name: 'login',
            path: '/user/login',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__login__model.ts' */ '/usr/src/app/src/pages/user/login/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__login" */ '../user/login'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/login').default,
            exact: true,
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        Routes: [require('../Authorized').default],
        routes: [
          {
            path: '/order',
            icon: 'file-search',
            name: 'order',
            routes: [
              {
                name: 'list',
                path: '/order/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__order__list__model.ts' */ '/usr/src/app/src/pages/order/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../order/list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../order/list').default,
                exact: true,
              },
              {
                path: '/order/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__order__detail__model.ts' */ '/usr/src/app/src/pages/order/detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../order/detail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../order/detail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/worder',
            icon: 'table',
            name: 'worder',
            routes: [
              {
                name: 'list',
                path: '/worder/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__worder__list__model.ts' */ '/usr/src/app/src/pages/worder/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../worder/list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../worder/list').default,
                exact: true,
              },
              {
                path: '/worder/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__worder__detail__model.ts' */ '/usr/src/app/src/pages/worder/detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../worder/detail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../worder/detail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/product',
            icon: 'shopping',
            name: 'product',
            routes: [
              {
                name: 'list',
                path: '/product/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__product__list__model.ts' */ '/usr/src/app/src/pages/product/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../product/list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../product/list').default,
                exact: true,
              },
              {
                path: '/product/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__product__detail__model.ts' */ '/usr/src/app/src/pages/product/detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../product/detail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../product/detail').default,
                exact: true,
              },
              {
                name: 'integral',
                path: '/product/integral',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__product__integral__model.ts' */ '/usr/src/app/src/pages/product/integral/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../product/integral'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../product/integral').default,
                exact: true,
              },
              {
                path: '/product/integralDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__product__integralDetail__model.ts' */ '/usr/src/app/src/pages/product/integralDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../product/integralDetail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../product/integralDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/activity',
            icon: 'shopping',
            name: 'activity',
            routes: [
              {
                name: 'signal',
                path: '/activity/signal',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__signal__model.ts' */ '/usr/src/app/src/pages/activity/signal/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/signal'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/signal').default,
                exact: true,
              },
              {
                path: '/activity/signalDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__signalDetail__model.ts' */ '/usr/src/app/src/pages/activity/signalDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/signalDetail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/signalDetail').default,
                exact: true,
              },
              {
                name: 'reduce',
                path: '/activity/reduce',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__reduce__model.ts' */ '/usr/src/app/src/pages/activity/reduce/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/reduce'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/reduce').default,
                exact: true,
              },
              {
                path: '/activity/reduceDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__reduceDetail__model.ts' */ '/usr/src/app/src/pages/activity/reduceDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/reduceDetail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/reduceDetail').default,
                exact: true,
              },
              {
                name: 'gift',
                path: '/activity/gift',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__gift__model.ts' */ '/usr/src/app/src/pages/activity/gift/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/gift'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/gift').default,
                exact: true,
              },
              {
                path: '/activity/giftDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__giftDetail__model.ts' */ '/usr/src/app/src/pages/activity/giftDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/giftDetail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/giftDetail').default,
                exact: true,
              },
              {
                name: 'promote',
                path: '/activity/promote',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__promote__model.ts' */ '/usr/src/app/src/pages/activity/promote/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/promote'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/promote').default,
                exact: true,
              },
              {
                path: '/activity/promoteDetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__activity__promoteDetail__model.ts' */ '/usr/src/app/src/pages/activity/promoteDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../activity/promoteDetail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../activity/promoteDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/coupon',
            icon: 'tags',
            name: 'coupon',
            routes: [
              {
                name: 'list',
                path: '/coupon/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__coupon__list__model.ts' */ '/usr/src/app/src/pages/coupon/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../coupon/list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../coupon/list').default,
                exact: true,
              },
              {
                path: '/coupon/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__coupon__detail__model.ts' */ '/usr/src/app/src/pages/coupon/detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../coupon/detail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../coupon/detail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/profile',
            name: 'profile',
            icon: 'user',
            routes: [
              {
                name: 'list',
                path: '/profile/list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__profile__list__model.ts' */ '/usr/src/app/src/pages/profile/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../profile/list'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../profile/list').default,
                exact: true,
              },
              {
                name: 'points',
                path: '/profile/points',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__profile__points__model.ts' */ '/usr/src/app/src/pages/profile/points/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../profile/points'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../profile/points').default,
                exact: true,
              },
              {
                path: '/profile/detail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__profile__detail__model.ts' */ '/usr/src/app/src/pages/profile/detail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../profile/detail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../profile/detail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/system',
            name: 'system',
            icon: 'setting',
            routes: [
              {
                name: 'userList',
                path: '/system/userlist',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__userList__model.ts' */ '/usr/src/app/src/pages/system/userList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/userList'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/userList').default,
                exact: true,
              },
              {
                path: '/system/userdetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__userDetail__model.ts' */ '/usr/src/app/src/pages/system/userDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/userDetail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/userDetail').default,
                exact: true,
              },
              {
                name: 'roleList',
                path: '/system/rolelist',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__roleList__model.ts' */ '/usr/src/app/src/pages/system/roleList/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/roleList'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/roleList').default,
                exact: true,
              },
              {
                path: '/system/roledetail',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__system__roleDetail__model.ts' */ '/usr/src/app/src/pages/system/roleDetail/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../system/roleDetail'),
                      LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../system/roleDetail').default,
                exact: true,
              },
              {
                component: () =>
                  React.createElement(
                    require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                      .default,
                    { pagesPath: 'src/pages', hasRoutesInConfig: true },
                  ),
              },
            ],
          },
          {
            path: '/',
            redirect: '/dashboard/analysis',
            authority: ['admin', 'user'],
            exact: true,
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  LoadingComponent: require('/usr/src/app/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
          {
            component: () =>
              React.createElement(
                require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
                  .default,
                { pagesPath: 'src/pages', hasRoutesInConfig: true },
              ),
          },
        ],
      },
      {
        component: () =>
          React.createElement(
            require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: true },
          ),
      },
    ],
  },
  {
    component: () =>
      React.createElement(
        require('/usr/src/app/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
          .default,
        { pagesPath: 'src/pages', hasRoutesInConfig: true },
      ),
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
