export default {
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  'menu.order': '订单',
  'menu.order.list': '订单列表',
  'menu.order.detail': '订单详情',
  'menu.worder': '仓管订单',
  'menu.worder.list': '仓管订单列表',
  'menu.worder.detail': '仓管订单详情',
  'menu.product': '商详',
  'menu.product.list': '商详列表',
  'menu.product.integral': '积分商品配置',
  'menu.product.integralDetail': '商品配置详情',
  'menu.product.control':'库存管理',
  'menu.activity': '活动配置',
  'menu.activity.signal': '暗号配置',
  'menu.activity.reduce': '满减配置',
  'menu.activity.gift': '满赠配置',
  'menu.activity.promote': '活动推广配置',
  'menu.coupon': '优惠券',
  'menu.coupon.list': '优惠券列表',
  'menu.profile': '用户',
  'menu.profile.qlist': '用户档案列表页',
  'menu.profile.list': '用户列表页',
  'menu.profile.points': '加积分页',
  'menu.profile.detail': '用户详情',
  'menu.system': '系统',
  'menu.system.userList': '系统用户列表页',
  'menu.system.roleList': '系统角色列表页',
  'menu.system.list': '系统列表页',
  'menu.system.detail': '系统详情',
};
